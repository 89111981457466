<template>
  <div v-if="isMatch">
    <router-view
      v-if="$route.fullPath !== '/entService'"
      v-slot="{ Component, id }"
    >
      <keep-alive include="freeTrial">
        <component
          :is="Component"
          v-if="$route.meta.keepAlive"
          :key="id"
        ></component>
      </keep-alive>
      <component
        :is="Component"
        v-if="!$route.meta.keepAlive"
        :key="id"
      ></component>
    </router-view>

    <div id="entService" v-else>
      <div class="ent_part1">
        <div class="part1_content flex_col">
          <div class="top flex">
            <div class="top_content flex_col">
              <div class="title flex_col">
                <span class="title queue-bottom">一站式</span>
                <span class="title queue-bottom">企业服务</span>
              </div>
              <div class="title_content queue-bottom animate_delay01">
                严格甄选30多项企业服务，为企业从成立到壮大全流程提供咨询及方案的落地实施。
              </div>
              <div
                class="freeBtn queue-bottom animate_delay02"
                @click="freeClick"
              >
                免费体验
              </div>
            </div>
          </div>
          <div class="bottom">
            <baskMask />
          </div>
        </div>
      </div>
      <div class="ent_part2">
        <div class="part2_content flex">
          <div>
            <span class="base_font queue-bottom" ref="part2_1"
              >1对1企业管家服务</span
            >
            <p class="queue-bottom" ref="part2_2">
              提供高效无忧的沟通渠道，从产品咨询到方案落地实施，提供全过程顾问式服务
            </p>
          </div>
          <img src="@/assets/images/entService/part1.png" alt="" />
        </div>
      </div>
      <div class="ent_part3">
        <div class="part3_content flex">
          <img src="@/assets/images/entService/part2.png" alt="" />
          <div>
            <span class="base_font queue-bottom" ref="part3_1"
              >数字化企业 服务工具</span
            >
            <span class="base_font queue-bottom" ref="part3_2"
              >查企业、查税务等数字化企业服务工具免费使用</span
            >
          </div>
        </div>
      </div>
      <div class="ent_part4">
        <div class="part4_content flex">
          <div class="content">
            <span class="queue-bottom" ref="part4_1"
              >严格甄选优质企业服务商</span
            >
            <p class="queue-bottom" ref="part4_2">
              覆盖工商财税、知识产权、法律咨询等多元企业服务
            </p>
          </div>
          <img src="@/assets/images/entService/part3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import baskMask from '@/common/baseMask.vue'

export default {
  name: 'entService',
  components: {
    baskMask
  },
  data() {
    return {}
  },
  computed: {
    isMatch() {
      return this.$route.fullPath.indexOf('/entService') >= 0
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(e) {
      if (e.target.documentElement.scrollTop >= 650) {
        if (this.$refs.part2_1 && this.$refs.part2_2) {
          this.$refs.part2_1.style.display = 'block'
          this.$refs.part2_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 1650) {
        if (this.$refs.part3_1 && this.$refs.part3_2) {
          this.$refs.part3_1.style.display = 'block'
          this.$refs.part3_2.style.display = 'block'
        }
      }
      if (e.target.documentElement.scrollTop >= 2450) {
        if (this.$refs.part4_1 && this.$refs.part4_2) {
          this.$refs.part4_1.style.display = 'block'
          this.$refs.part4_2.style.display = 'block'
        }
      }
    },
    freeClick() {
      this.$router.push('/entService/freeTrial')
    }
  }
}
</script>
{
<style lang="scss" scoped>
#entService {
  width: 100%;
  min-width: 1200px;
  .ent_part1 {
    padding-top: 177px;
    width: 100%;
    background: url(~assets/images/entService/bg1.png) no-repeat 50% 50%;
    background-size: cover;
    .part1_content {
      width: 1200px;
      margin: auto;
      .top {
        margin-left: 43px;
        .top_content {
          align-items: flex-start;
          .title {
            align-items: flex-start;
            span {
              font-size: 67px;
              font-weight: bold;
              line-height: 81px;
              color: #dbbb95;
              letter-spacing: 1px;
            }
          }
          .title_content {
            text-align: left;
            margin-top: 35px;
            font-size: 26px;
            font-weight: 400;
            line-height: 39px;
            color: #ffffff;
            max-width: 544px;
          }
          .freeBtn {
            width: 196px;
            height: 50px;
            background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
            border-radius: 100px;
            font-size: 20px;
            font-weight: bold;
            color: #230a01;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
          }
        }
      }
      .bottom {
        background: #161616;
        transform: translateY(171px);
      }
    }
  }
  .ent_part2 {
    width: 100%;
    background: url(~assets/images/entService/bg2.jpg) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 247px;
    .part2_content {
      width: 1200px;
      margin: auto;
      justify-content: flex-end;
      & > div {
        margin-right: -71px;
        padding-left: 87px;
        padding-top: 125px;
        span {
          max-width: 288px;
          display: none;
        }
        p {
          margin-top: 30px;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #ffffff;
          max-width: 438px;
          display: none;
        }
      }
    }
  }
  .ent_part3 {
    width: 100%;
    background: url(~assets/images/entService/bg3.png) no-repeat 50% 50%;
    background-size: cover;
    padding-top: 89px;
    .part3_content {
      width: 1200px;
      margin: auto;
      & > div {
        width: 402px;
        padding-top: 111px;
        margin-left: -79px;
        span {
          max-width: 385px;
          display: none;
          text-align: right;
        }
        span:nth-of-type(2) {
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #ffffff;
          margin-top: 36px;
        }
      }
    }
  }
  .ent_part4 {
    width: 100%;
    background: #fff;
    .part4_content {
      padding-top: 118px;
      width: 1200px;
      margin: auto;
      justify-content: center;
      img {
        width: 557px;
        height: 812px;
      }
      .content {
        width: 528px;
        padding-top: 38px;
        padding-left: 97px;
        span {
          display: none;
          font-size: 67px;
          font-weight: 400;
          line-height: 87px;
          color: #333333;
          max-width: 402px;
        }
        p {
          display: none;
          margin-top: 20px;
          font-size: 26px;
          font-weight: 400;
          line-height: 43px;
          color: #333333;
          max-width: 448px;
        }
      }
    }
  }
}
</style>
